@mixin darkTheme
{
	--colorNeutralForeground1: #ffffff;
	--colorNeutralForeground1Hover: #ffffff;
	--colorNeutralForeground1Pressed: #ffffff;
	--colorNeutralForeground1Selected: #ffffff;
	--colorNeutralForeground2: #d6d6d6;
	--colorNeutralForeground2Hover: #ffffff;
	--colorNeutralForeground2Pressed: #ffffff;
	--colorNeutralForeground2Selected: #ffffff;
	--colorNeutralForeground2BrandHover: #c95e38;
	--colorNeutralForeground2BrandPressed: #b35433;
	--colorNeutralForeground2BrandSelected: #c95e38;
	--colorNeutralForeground3: #adadad;
	--colorNeutralForeground3Hover: #d6d6d6;
	--colorNeutralForeground3Pressed: #d6d6d6;
	--colorNeutralForeground3Selected: #d6d6d6;
	--colorNeutralForeground3BrandHover: #c95e38;
	--colorNeutralForeground3BrandPressed: #b35433;
	--colorNeutralForeground3BrandSelected: #c95e38;
	--colorNeutralForeground4: #999999;
	--colorNeutralForegroundDisabled: #5c5c5c;
	--colorNeutralForegroundInvertedDisabled: rgba(255, 255, 255, 0.4);
	--colorBrandForegroundLink: #c95e38;
	--colorBrandForegroundLinkHover: #e0683e;
	--colorBrandForegroundLinkPressed: #b35433;
	--colorBrandForegroundLinkSelected: #c95e38;
	--colorNeutralForeground2Link: #d6d6d6;
	--colorNeutralForeground2LinkHover: #ffffff;
	--colorNeutralForeground2LinkPressed: #ffffff;
	--colorNeutralForeground2LinkSelected: #ffffff;
	--colorCompoundBrandForeground1: #c95e38;
	--colorCompoundBrandForeground1Hover: #e0683e;
	--colorCompoundBrandForeground1Pressed: #b35433;
	--colorBrandForeground1: #e0683e;
	--colorBrandForeground2: #f77243;
	--colorBrandForeground2Hover: #ff875b;
	--colorBrandForeground2Pressed: #ffcbb8;
	--colorNeutralForeground1Static: #242424;
	--colorNeutralForegroundStaticInverted: #ffffff;
	--colorNeutralForegroundInverted: #242424;
	--colorNeutralForegroundInvertedHover: #242424;
	--colorNeutralForegroundInvertedPressed: #242424;
	--colorNeutralForegroundInvertedSelected: #242424;
	--colorNeutralForegroundInverted2: #242424;
	--colorNeutralForegroundOnBrand: #ffffff;
	--colorNeutralForegroundInvertedLink: #ffffff;
	--colorNeutralForegroundInvertedLinkHover: #ffffff;
	--colorNeutralForegroundInvertedLinkPressed: #ffffff;
	--colorNeutralForegroundInvertedLinkSelected: #ffffff;
	--colorBrandForegroundInverted: #9d4b2e;
	--colorBrandForegroundInvertedHover: #874229;
	--colorBrandForegroundInvertedPressed: #723924;
	--colorBrandForegroundOnLight: #9d4b2e;
	--colorBrandForegroundOnLightHover: #874229;
	--colorBrandForegroundOnLightPressed: #5e301f;
	--colorBrandForegroundOnLightSelected: #723924;
	--colorNeutralBackground1: #292929;
	--colorNeutralBackground1Hover: #3d3d3d;
	--colorNeutralBackground1Pressed: #1f1f1f;
	--colorNeutralBackground1Selected: #383838;
	--colorNeutralBackground2: #1f1f1f;
	--colorNeutralBackground2Hover: #333333;
	--colorNeutralBackground2Pressed: #141414;
	--colorNeutralBackground2Selected: #2e2e2e;
	--colorNeutralBackground3: #141414;
	--colorNeutralBackground3Hover: #292929;
	--colorNeutralBackground3Pressed: #0a0a0a;
	--colorNeutralBackground3Selected: #242424;
	--colorNeutralBackground4: #0a0a0a;
	--colorNeutralBackground4Hover: #1f1f1f;
	--colorNeutralBackground4Pressed: #000000;
	--colorNeutralBackground4Selected: #1a1a1a;
	--colorNeutralBackground5: #000000;
	--colorNeutralBackground5Hover: #141414;
	--colorNeutralBackground5Pressed: #050505;
	--colorNeutralBackground5Selected: #0f0f0f;
	--colorNeutralBackground6: #333333;
	--colorNeutralBackgroundInverted: #ffffff;
	--colorNeutralBackgroundStatic: #3d3d3d;
	--colorNeutralBackgroundAlpha: rgba(26, 26, 26, 0.5);
	--colorNeutralBackgroundAlpha2: rgba(31, 31, 31, 0.7);
	--colorSubtleBackground: transparent;
	--colorSubtleBackgroundHover: #383838;
	--colorSubtleBackgroundPressed: #2e2e2e;
	--colorSubtleBackgroundSelected: #333333;
	--colorSubtleBackgroundLightAlphaHover: rgba(36, 36, 36, 0.8);
	--colorSubtleBackgroundLightAlphaPressed: rgba(36, 36, 36, 0.5);
	--colorSubtleBackgroundLightAlphaSelected: transparent;
	--colorSubtleBackgroundInverted: transparent;
	--colorSubtleBackgroundInvertedHover: rgba(0, 0, 0, 0.1);
	--colorSubtleBackgroundInvertedPressed: rgba(0, 0, 0, 0.3);
	--colorSubtleBackgroundInvertedSelected: rgba(0, 0, 0, 0.2);
	--colorTransparentBackground: transparent;
	--colorTransparentBackgroundHover: transparent;
	--colorTransparentBackgroundPressed: transparent;
	--colorTransparentBackgroundSelected: transparent;
	--colorNeutralBackgroundDisabled: #141414;
	--colorNeutralBackgroundInvertedDisabled: rgba(255, 255, 255, 0.1);
	--colorNeutralStencil1: #575757;
	--colorNeutralStencil2: #333333;
	--colorNeutralStencil1Alpha: rgba(255, 255, 255, 0.1);
	--colorNeutralStencil2Alpha: rgba(255, 255, 255, 0.05);
	--colorBackgroundOverlay: rgba(0, 0, 0, 0.5);
	--colorScrollbarOverlay: rgba(255, 255, 255, 0.6);
	--colorBrandBackground: #874229;
	--colorBrandBackgroundHover: #9d4b2e;
	--colorBrandBackgroundPressed: #4b271a;
	--colorBrandBackgroundSelected: #723924;
	--colorCompoundBrandBackground: #c95e38;
	--colorCompoundBrandBackgroundHover: #e0683e;
	--colorCompoundBrandBackgroundPressed: #b35433;
	--colorBrandBackgroundStatic: #9d4b2e;
	--colorBrandBackground2: #21140d;
	--colorBrandBackground2Hover: #4b271a;
	--colorBrandBackground2Pressed: #050201;
	--colorBrandBackgroundInverted: #ffffff;
	--colorBrandBackgroundInvertedHover: #ffcbb8;
	--colorBrandBackgroundInvertedPressed: #ff9f7c;
	--colorBrandBackgroundInvertedSelected: #ffb69a;
	--colorNeutralStrokeAccessible: #adadad;
	--colorNeutralStrokeAccessibleHover: #bdbdbd;
	--colorNeutralStrokeAccessiblePressed: #b3b3b3;
	--colorNeutralStrokeAccessibleSelected: #c95e38;
	--colorNeutralStroke1: #666666;
	--colorNeutralStroke1Hover: #757575;
	--colorNeutralStroke1Pressed: #6b6b6b;
	--colorNeutralStroke1Selected: #707070;
	--colorNeutralStroke2: #525252;
	--colorNeutralStroke3: #3d3d3d;
	--colorNeutralStrokeSubtle: #0a0a0a;
	--colorNeutralStrokeOnBrand: #292929;
	--colorNeutralStrokeOnBrand2: #ffffff;
	--colorNeutralStrokeOnBrand2Hover: #ffffff;
	--colorNeutralStrokeOnBrand2Pressed: #ffffff;
	--colorNeutralStrokeOnBrand2Selected: #ffffff;
	--colorBrandStroke1: #c95e38;
	--colorBrandStroke2: #5e301f;
	--colorBrandStroke2Hover: #5e301f;
	--colorBrandStroke2Pressed: #381f15;
	--colorBrandStroke2Contrast: #5e301f;
	--colorCompoundBrandStroke: #c95e38;
	--colorCompoundBrandStrokeHover: #e0683e;
	--colorCompoundBrandStrokePressed: #b35433;
	--colorNeutralStrokeDisabled: #424242;
	--colorNeutralStrokeInvertedDisabled: rgba(255, 255, 255, 0.4);
	--colorTransparentStroke: transparent;
	--colorTransparentStrokeInteractive: transparent;
	--colorTransparentStrokeDisabled: transparent;
	--colorNeutralStrokeAlpha: rgba(255, 255, 255, 0.1);
	--colorNeutralStrokeAlpha2: rgba(255, 255, 255, 0.2);
	--colorStrokeFocus1: #000000;
	--colorStrokeFocus2: #ffffff;
	--colorNeutralShadowAmbient: rgba(0,0,0,0.24);
	--colorNeutralShadowKey: rgba(0,0,0,0.28);
	--colorNeutralShadowAmbientLighter: rgba(0,0,0,0.12);
	--colorNeutralShadowKeyLighter: rgba(0,0,0,0.14);
	--colorNeutralShadowAmbientDarker: rgba(0,0,0,0.40);
	--colorNeutralShadowKeyDarker: rgba(0,0,0,0.48);
	--colorBrandShadowAmbient: rgba(0,0,0,0.30);
	--colorBrandShadowKey: rgba(0,0,0,0.25);
	--colorPaletteRedBackground1: #3f1011;
	--colorPaletteRedBackground2: #751d1f;
	--colorPaletteRedBackground3: #d13438;
	--colorPaletteRedForeground1: #e37d80;
	--colorPaletteRedForeground2: #f1bbbc;
	--colorPaletteRedForeground3: #e37d80;
	--colorPaletteRedBorderActive: #e37d80;
	--colorPaletteRedBorder1: #d13438;
	--colorPaletteRedBorder2: #e37d80;
	--colorPaletteGreenBackground1: #052505;
	--colorPaletteGreenBackground2: #094509;
	--colorPaletteGreenBackground3: #107c10;
	--colorPaletteGreenForeground1: #54b054;
	--colorPaletteGreenForeground2: #9fd89f;
	--colorPaletteGreenForeground3: #9fd89f;
	--colorPaletteGreenBorderActive: #54b054;
	--colorPaletteGreenBorder1: #107c10;
	--colorPaletteGreenBorder2: #9fd89f;
	--colorPaletteDarkOrangeBackground1: #411200;
	--colorPaletteDarkOrangeBackground2: #7a2101;
	--colorPaletteDarkOrangeBackground3: #da3b01;
	--colorPaletteDarkOrangeForeground1: #e9835e;
	--colorPaletteDarkOrangeForeground2: #f4bfab;
	--colorPaletteDarkOrangeForeground3: #e9835e;
	--colorPaletteDarkOrangeBorderActive: #e9835e;
	--colorPaletteDarkOrangeBorder1: #da3b01;
	--colorPaletteDarkOrangeBorder2: #e9835e;
	--colorPaletteYellowBackground1: #4c4400;
	--colorPaletteYellowBackground2: #817400;
	--colorPaletteYellowBackground3: #fde300;
	--colorPaletteYellowForeground1: #feee66;
	--colorPaletteYellowForeground2: #fef7b2;
	--colorPaletteYellowForeground3: #fdea3d;
	--colorPaletteYellowBorderActive: #feee66;
	--colorPaletteYellowBorder1: #fde300;
	--colorPaletteYellowBorder2: #fdea3d;
	--colorPaletteBerryBackground1: #3a1136;
	--colorPaletteBerryBackground2: #6d2064;
	--colorPaletteBerryBackground3: #c239b3;
	--colorPaletteBerryForeground1: #da7ed0;
	--colorPaletteBerryForeground2: #edbbe7;
	--colorPaletteBerryForeground3: #d161c4;
	--colorPaletteBerryBorderActive: #da7ed0;
	--colorPaletteBerryBorder1: #c239b3;
	--colorPaletteBerryBorder2: #d161c4;
	--colorPaletteLightGreenBackground1: #063004;
	--colorPaletteLightGreenBackground2: #0b5a08;
	--colorPaletteLightGreenBackground3: #13a10e;
	--colorPaletteLightGreenForeground1: #5ec75a;
	--colorPaletteLightGreenForeground2: #a7e3a5;
	--colorPaletteLightGreenForeground3: #3db838;
	--colorPaletteLightGreenBorderActive: #5ec75a;
	--colorPaletteLightGreenBorder1: #13a10e;
	--colorPaletteLightGreenBorder2: #3db838;
	--colorPaletteMarigoldBackground1: #463100;
	--colorPaletteMarigoldBackground2: #835b00;
	--colorPaletteMarigoldBackground3: #eaa300;
	--colorPaletteMarigoldForeground1: #f2c661;
	--colorPaletteMarigoldForeground2: #f9e2ae;
	--colorPaletteMarigoldForeground3: #efb839;
	--colorPaletteMarigoldBorderActive: #f2c661;
	--colorPaletteMarigoldBorder1: #eaa300;
	--colorPaletteMarigoldBorder2: #efb839;
	--colorPaletteRedForegroundInverted: #d13438;
	--colorPaletteGreenForegroundInverted: #107c10;
	--colorPaletteYellowForegroundInverted: #817400;
	--colorPaletteDarkRedBackground2: #590815;
	--colorPaletteDarkRedForeground2: #d69ca5;
	--colorPaletteDarkRedBorderActive: #ac4f5e;
	--colorPaletteCranberryBackground2: #6e0811;
	--colorPaletteCranberryForeground2: #eeacb2;
	--colorPaletteCranberryBorderActive: #dc626d;
	--colorPalettePumpkinBackground2: #712d09;
	--colorPalettePumpkinForeground2: #efc4ad;
	--colorPalettePumpkinBorderActive: #df8e64;
	--colorPalettePeachBackground2: #8f4e00;
	--colorPalettePeachForeground2: #ffddb3;
	--colorPalettePeachBorderActive: #ffba66;
	--colorPaletteGoldBackground2: #6c5700;
	--colorPaletteGoldForeground2: #ecdfa5;
	--colorPaletteGoldBorderActive: #dac157;
	--colorPaletteBrassBackground2: #553e06;
	--colorPaletteBrassForeground2: #e0cea2;
	--colorPaletteBrassBorderActive: #c1a256;
	--colorPaletteBrownBackground2: #50301a;
	--colorPaletteBrownForeground2: #ddc3b0;
	--colorPaletteBrownBorderActive: #bb8f6f;
	--colorPaletteForestBackground2: #294903;
	--colorPaletteForestForeground2: #bdd99b;
	--colorPaletteForestBorderActive: #85b44c;
	--colorPaletteSeafoamBackground2: #00723b;
	--colorPaletteSeafoamForeground2: #a8f0cd;
	--colorPaletteSeafoamBorderActive: #5ae0a0;
	--colorPaletteDarkGreenBackground2: #063b06;
	--colorPaletteDarkGreenForeground2: #9ad29a;
	--colorPaletteDarkGreenBorderActive: #4da64d;
	--colorPaletteLightTealBackground2: #00666d;
	--colorPaletteLightTealForeground2: #a6e9ed;
	--colorPaletteLightTealBorderActive: #58d3db;
	--colorPaletteTealBackground2: #02494c;
	--colorPaletteTealForeground2: #9bd9db;
	--colorPaletteTealBorderActive: #4cb4b7;
	--colorPaletteSteelBackground2: #00333f;
	--colorPaletteSteelForeground2: #94c8d4;
	--colorPaletteSteelBorderActive: #4496a9;
	--colorPaletteBlueBackground2: #004377;
	--colorPaletteBlueForeground2: #a9d3f2;
	--colorPaletteBlueBorderActive: #5caae5;
	--colorPaletteRoyalBlueBackground2: #002c4e;
	--colorPaletteRoyalBlueForeground2: #9abfdc;
	--colorPaletteRoyalBlueBorderActive: #4a89ba;
	--colorPaletteCornflowerBackground2: #2c3c85;
	--colorPaletteCornflowerForeground2: #c8d1fa;
	--colorPaletteCornflowerBorderActive: #93a4f4;
	--colorPaletteNavyBackground2: #001665;
	--colorPaletteNavyForeground2: #a3b2e8;
	--colorPaletteNavyBorderActive: #546fd2;
	--colorPaletteLavenderBackground2: #3f3682;
	--colorPaletteLavenderForeground2: #d2ccf8;
	--colorPaletteLavenderBorderActive: #a79cf1;
	--colorPalettePurpleBackground2: #341a51;
	--colorPalettePurpleForeground2: #c6b1de;
	--colorPalettePurpleBorderActive: #9470bd;
	--colorPaletteGrapeBackground2: #4c0d55;
	--colorPaletteGrapeForeground2: #d9a7e0;
	--colorPaletteGrapeBorderActive: #b55fc1;
	--colorPaletteLilacBackground2: #63276d;
	--colorPaletteLilacForeground2: #e6bfed;
	--colorPaletteLilacBorderActive: #cf87da;
	--colorPalettePinkBackground2: #80215d;
	--colorPalettePinkForeground2: #f7c0e3;
	--colorPalettePinkBorderActive: #ef85c8;
	--colorPaletteMagentaBackground2: #6b0043;
	--colorPaletteMagentaForeground2: #eca5d1;
	--colorPaletteMagentaBorderActive: #d957a8;
	--colorPalettePlumBackground2: #5a003b;
	--colorPalettePlumForeground2: #d696c0;
	--colorPalettePlumBorderActive: #ad4589;
	--colorPaletteBeigeBackground2: #444241;
	--colorPaletteBeigeForeground2: #d7d4d4;
	--colorPaletteBeigeBorderActive: #afabaa;
	--colorPaletteMinkBackground2: #343231;
	--colorPaletteMinkForeground2: #cecccb;
	--colorPaletteMinkBorderActive: #9e9b99;
	--colorPalettePlatinumBackground2: #3b4447;
	--colorPalettePlatinumForeground2: #cdd6d8;
	--colorPalettePlatinumBorderActive: #a0adb2;
	--colorPaletteAnchorBackground2: #202427;
	--colorPaletteAnchorForeground2: #bcc3c7;
	--colorPaletteAnchorBorderActive: #808a90;
	--colorStatusSuccessBackground1: #052505;
	--colorStatusSuccessBackground2: #094509;
	--colorStatusSuccessBackground3: #107c10;
	--colorStatusSuccessForeground1: #54b054;
	--colorStatusSuccessForeground2: #9fd89f;
	--colorStatusSuccessForeground3: #9fd89f;
	--colorStatusSuccessBorderActive: #54b054;
	--colorStatusSuccessForegroundInverted: #0e700e;
	--colorStatusSuccessBorder1: #107c10;
	--colorStatusSuccessBorder2: #9fd89f;
	--colorStatusWarningBackground1: #4a1e04;
	--colorStatusWarningBackground2: #8a3707;
	--colorStatusWarningBackground3: #f7630c;
	--colorStatusWarningForeground1: #faa06b;
	--colorStatusWarningForeground2: #fdcfb4;
	--colorStatusWarningForeground3: #f98845;
	--colorStatusWarningBorderActive: #faa06b;
	--colorStatusWarningForegroundInverted: #bc4b09;
	--colorStatusWarningBorder1: #f7630c;
	--colorStatusWarningBorder2: #f98845;
	--colorStatusDangerBackground1: #3b0509;
	--colorStatusDangerBackground2: #6e0811;
	--colorStatusDangerBackground3: #c50f1f;
	--colorStatusDangerForeground1: #dc626d;
	--colorStatusDangerForeground2: #eeacb2;
	--colorStatusDangerForeground3: #dc626d;
	--colorStatusDangerBorderActive: #dc626d;
	--colorStatusDangerForegroundInverted: #b10e1c;
	--colorStatusDangerBorder1: #c50f1f;
	--colorStatusDangerBorder2: #dc626d;
}
