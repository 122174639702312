// Border radius
$borderRadiusNone: 0;
$borderRadiusSmall: 2px;
$borderRadiusMedium: 4px;
$borderRadiusLarge: 6px;
$borderRadiusXLarge: 8px;
$borderRadiusCircular: 10000px;

// Motion / Curves
$curveAccelerateMax: cubic-bezier(1,0,1,1);
$curveAccelerateMid: cubic-bezier(0.7,0,1,0.5);
$curveAccelerateMin: cubic-bezier(0.8,0,1,1);
$curveDecelerateMax: cubic-bezier(0,0,0,1);
$curveDecelerateMid: cubic-bezier(0.1,0.9,0.2,1);
$curveDecelerateMin: cubic-bezier(0.33,0,0.1,1);
$curveEasyEaseMax: cubic-bezier(0.8,0,0.1,1);
$curveEasyEase: cubic-bezier(0.33,0,0.67,1);
$curveLinear: cubic-bezier(0,0,1,1);

// Motion / Durations
$durationUltraFast: 50ms;
$durationFaster: 100ms;
$durationFast: 150ms;
$durationNormal: 200ms;
$durationGentle: 250ms;
$durationSlow: 300ms;
$durationSlower: 400ms;
$durationUltraSlow: 500ms;

// Shadows
$shadow2: 0 0 2px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.28);
$shadow4: 0 0 2px rgba(0,0,0,0.24), 0 2px 4px rgba(0,0,0,0.28);
$shadow8: 0 0 2px rgba(0,0,0,0.24), 0 4px 8px rgba(0,0,0,0.28);
$shadow16: 0 0 2px rgba(0,0,0,0.24), 0 8px 16px rgba(0,0,0,0.28);
$shadow28: 0 0 8px rgba(0,0,0,0.24), 0 14px 28px rgba(0,0,0,0.28);
$shadow64: 0 0 8px rgba(0,0,0,0.24), 0 32px 64px rgba(0,0,0,0.28);
$shadow2Brand: 0 0 2px rgba(0,0,0,0.30), 0 1px 2px rgba(0,0,0,0.25);
$shadow4Brand: 0 0 2px rgba(0,0,0,0.30), 0 2px 4px rgba(0,0,0,0.25);
$shadow8Brand: 0 0 2px rgba(0,0,0,0.30), 0 4px 8px rgba(0,0,0,0.25);
$shadow16Brand: 0 0 2px rgba(0,0,0,0.30), 0 8px 16px rgba(0,0,0,0.25);
$shadow28Brand: 0 0 8px rgba(0,0,0,0.30), 0 14px 28px rgba(0,0,0,0.25);
$shadow64Brand: 0 0 8px rgba(0,0,0,0.30), 0 32px 64px rgba(0,0,0,0.25);

// Spacing
$spacingNone: 0;
$spacingXXS: 2px;
$spacingXS: 4px;
$spacingSNudge: 6px;
$spacingS: 8px;
$spacingMNudge: 10px;
$spacingM: 12px;
$spacingL: 16px;
$spacingXL: 20px;
$spacingXXL: 24px;
$spacingXXXL: 32px;

// Stroke widths
$strokeWidthThin: 1px;
$strokeWidthThick: 2px;
$strokeWidthThicker: 3px;
$strokeWidthThickest: 4px;

// Fonts / Font family
$fontFamilyBase: var(--font-base), "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$fontFamilyBaseAlt: var(--font-alt), "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$fontFamilyMonospace: var(--font-mono), Consolas, "Courier New", Courier, monospace;
$fontFamilyNumeric: var(--font-base), Bahnschrift, "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;

// Fonts / Font size
$fontSizeBase100: 10px;
$fontSizeBase200: 12px;
$fontSizeBase300: 14px;
$fontSizeBase400: 16px;
$fontSizeBase500: 20px;
$fontSizeBase600: 24px;
$fontSizeHero700: 28px;
$fontSizeHero800: 32px;
$fontSizeHero900: 40px;
$fontSizeHero1000: 68px;

// Fonts / Font weight
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;
$fontWeightBold: 700;

// Fonts / Line height
$lineHeightBase100: 14px;
$lineHeightBase200: 16px;
$lineHeightBase300: 20px;
$lineHeightBase400: 22px;
$lineHeightBase500: 28px;
$lineHeightBase600: 32px;
$lineHeightHero700: 36px;
$lineHeightHero800: 40px;
$lineHeightHero900: 52px;
$lineHeightHero1000: 92px;

// Colors
$colorNeutralForeground1: var(--colorNeutralForeground1);
$colorNeutralForeground1Hover: var(--colorNeutralForeground1Hover);
$colorNeutralForeground1Pressed: var(--colorNeutralForeground1Pressed);
$colorNeutralForeground1Selected: var(--colorNeutralForeground1Selected);
$colorNeutralForeground2: var(--colorNeutralForeground2);
$colorNeutralForeground2Hover: var(--colorNeutralForeground2Hover);
$colorNeutralForeground2Pressed: var(--colorNeutralForeground2Pressed);
$colorNeutralForeground2Selected: var(--colorNeutralForeground2Selected);
$colorNeutralForeground2BrandHover: var(--colorNeutralForeground2BrandHover);
$colorNeutralForeground2BrandPressed: var(--colorNeutralForeground2BrandPressed);
$colorNeutralForeground2BrandSelected: var(--colorNeutralForeground2BrandSelected);
$colorNeutralForeground3: var(--colorNeutralForeground3);
$colorNeutralForeground3Hover: var(--colorNeutralForeground3Hover);
$colorNeutralForeground3Pressed: var(--colorNeutralForeground3Pressed);
$colorNeutralForeground3Selected: var(--colorNeutralForeground3Selected);
$colorNeutralForeground3BrandHover: var(--colorNeutralForeground3BrandHover);
$colorNeutralForeground3BrandPressed: var(--colorNeutralForeground3BrandPressed);
$colorNeutralForeground3BrandSelected: var(--colorNeutralForeground3BrandSelected);
$colorNeutralForeground4: var(--colorNeutralForeground4);
$colorNeutralForegroundDisabled: var(--colorNeutralForegroundDisabled);
$colorNeutralForegroundInvertedDisabled: var(--colorNeutralForegroundInvertedDisabled);
$colorBrandForegroundLink: var(--colorBrandForegroundLink);
$colorBrandForegroundLinkHover: var(--colorBrandForegroundLinkHover);
$colorBrandForegroundLinkPressed: var(--colorBrandForegroundLinkPressed);
$colorBrandForegroundLinkSelected: var(--colorBrandForegroundLinkSelected);
$colorNeutralForeground2Link: var(--colorNeutralForeground2Link);
$colorNeutralForeground2LinkHover: var(--colorNeutralForeground2LinkHover);
$colorNeutralForeground2LinkPressed: var(--colorNeutralForeground2LinkPressed);
$colorNeutralForeground2LinkSelected: var(--colorNeutralForeground2LinkSelected);
$colorCompoundBrandForeground1: var(--colorCompoundBrandForeground1);
$colorCompoundBrandForeground1Hover: var(--colorCompoundBrandForeground1Hover);
$colorCompoundBrandForeground1Pressed: var(--colorCompoundBrandForeground1Pressed);
$colorBrandForeground1: var(--colorBrandForeground1);
$colorBrandForeground2: var(--colorBrandForeground2);
$colorBrandForeground2Hover: var(--colorBrandForeground2Hover);
$colorBrandForeground2Pressed: var(--colorBrandForeground2Pressed);
$colorNeutralForeground1Static: var(--colorNeutralForeground1Static);
$colorNeutralForegroundStaticInverted: var(--colorNeutralForegroundStaticInverted);
$colorNeutralForegroundInverted: var(--colorNeutralForegroundInverted);
$colorNeutralForegroundInvertedHover: var(--colorNeutralForegroundInvertedHover);
$colorNeutralForegroundInvertedPressed: var(--colorNeutralForegroundInvertedPressed);
$colorNeutralForegroundInvertedSelected: var(--colorNeutralForegroundInvertedSelected);
$colorNeutralForegroundInverted2: var(--colorNeutralForegroundInverted2);
$colorNeutralForegroundOnBrand: var(--colorNeutralForegroundOnBrand);
$colorNeutralForegroundInvertedLink: var(--colorNeutralForegroundInvertedLink);
$colorNeutralForegroundInvertedLinkHover: var(--colorNeutralForegroundInvertedLinkHover);
$colorNeutralForegroundInvertedLinkPressed: var(--colorNeutralForegroundInvertedLinkPressed);
$colorNeutralForegroundInvertedLinkSelected: var(--colorNeutralForegroundInvertedLinkSelected);
$colorBrandForegroundInverted: var(--colorBrandForegroundInverted);
$colorBrandForegroundInvertedHover: var(--colorBrandForegroundInvertedHover);
$colorBrandForegroundInvertedPressed: var(--colorBrandForegroundInvertedPressed);
$colorBrandForegroundOnLight: var(--colorBrandForegroundOnLight);
$colorBrandForegroundOnLightHover: var(--colorBrandForegroundOnLightHover);
$colorBrandForegroundOnLightPressed: var(--colorBrandForegroundOnLightPressed);
$colorBrandForegroundOnLightSelected: var(--colorBrandForegroundOnLightSelected);
$colorNeutralBackground1: var(--colorNeutralBackground1);
$colorNeutralBackground1Hover: var(--colorNeutralBackground1Hover);
$colorNeutralBackground1Pressed: var(--colorNeutralBackground1Pressed);
$colorNeutralBackground1Selected: var(--colorNeutralBackground1Selected);
$colorNeutralBackground2: var(--colorNeutralBackground2);
$colorNeutralBackground2Hover: var(--colorNeutralBackground2Hover);
$colorNeutralBackground2Pressed: var(--colorNeutralBackground2Pressed);
$colorNeutralBackground2Selected: var(--colorNeutralBackground2Selected);
$colorNeutralBackground3: var(--colorNeutralBackground3);
$colorNeutralBackground3Hover: var(--colorNeutralBackground3Hover);
$colorNeutralBackground3Pressed: var(--colorNeutralBackground3Pressed);
$colorNeutralBackground3Selected: var(--colorNeutralBackground3Selected);
$colorNeutralBackground4: var(--colorNeutralBackground4);
$colorNeutralBackground4Hover: var(--colorNeutralBackground4Hover);
$colorNeutralBackground4Pressed: var(--colorNeutralBackground4Pressed);
$colorNeutralBackground4Selected: var(--colorNeutralBackground4Selected);
$colorNeutralBackground5: var(--colorNeutralBackground5);
$colorNeutralBackground5Hover: var(--colorNeutralBackground5Hover);
$colorNeutralBackground5Pressed: var(--colorNeutralBackground5Pressed);
$colorNeutralBackground5Selected: var(--colorNeutralBackground5Selected);
$colorNeutralBackground6: var(--colorNeutralBackground6);
$colorNeutralBackgroundInverted: var(--colorNeutralBackgroundInverted);
$colorNeutralBackgroundStatic: var(--colorNeutralBackgroundStatic);
$colorNeutralBackgroundAlpha: var(--colorNeutralBackgroundAlpha);
$colorNeutralBackgroundAlpha2: var(--colorNeutralBackgroundAlpha2);
$colorSubtleBackground: var(--colorSubtleBackground);
$colorSubtleBackgroundHover: var(--colorSubtleBackgroundHover);
$colorSubtleBackgroundPressed: var(--colorSubtleBackgroundPressed);
$colorSubtleBackgroundSelected: var(--colorSubtleBackgroundSelected);
$colorSubtleBackgroundLightAlphaHover: var(--colorSubtleBackgroundLightAlphaHover);
$colorSubtleBackgroundLightAlphaPressed: var(--colorSubtleBackgroundLightAlphaPressed);
$colorSubtleBackgroundLightAlphaSelected: var(--colorSubtleBackgroundLightAlphaSelected);
$colorSubtleBackgroundInverted: var(--colorSubtleBackgroundInverted);
$colorSubtleBackgroundInvertedHover: var(--colorSubtleBackgroundInvertedHover);
$colorSubtleBackgroundInvertedPressed: var(--colorSubtleBackgroundInvertedPressed);
$colorSubtleBackgroundInvertedSelected: var(--colorSubtleBackgroundInvertedSelected);
$colorTransparentBackground: var(--colorTransparentBackground);
$colorTransparentBackgroundHover: var(--colorTransparentBackgroundHover);
$colorTransparentBackgroundPressed: var(--colorTransparentBackgroundPressed);
$colorTransparentBackgroundSelected: var(--colorTransparentBackgroundSelected);
$colorNeutralBackgroundDisabled: var(--colorNeutralBackgroundDisabled);
$colorNeutralBackgroundInvertedDisabled: var(--colorNeutralBackgroundInvertedDisabled);
$colorNeutralStencil1: var(--colorNeutralStencil1);
$colorNeutralStencil2: var(--colorNeutralStencil2);
$colorNeutralStencil1Alpha: var(--colorNeutralStencil1Alpha);
$colorNeutralStencil2Alpha: var(--colorNeutralStencil2Alpha);
$colorBackgroundOverlay: var(--colorBackgroundOverlay);
$colorScrollbarOverlay: var(--colorScrollbarOverlay);
$colorBrandBackground: var(--colorBrandBackground);
$colorBrandBackgroundHover: var(--colorBrandBackgroundHover);
$colorBrandBackgroundPressed: var(--colorBrandBackgroundPressed);
$colorBrandBackgroundSelected: var(--colorBrandBackgroundSelected);
$colorCompoundBrandBackground: var(--colorCompoundBrandBackground);
$colorCompoundBrandBackgroundHover: var(--colorCompoundBrandBackgroundHover);
$colorCompoundBrandBackgroundPressed: var(--colorCompoundBrandBackgroundPressed);
$colorBrandBackgroundStatic: var(--colorBrandBackgroundStatic);
$colorBrandBackground2: var(--colorBrandBackground2);
$colorBrandBackground2Hover: var(--colorBrandBackground2Hover);
$colorBrandBackground2Pressed: var(--colorBrandBackground2Pressed);
$colorBrandBackgroundInverted: var(--colorBrandBackgroundInverted);
$colorBrandBackgroundInvertedHover: var(--colorBrandBackgroundInvertedHover);
$colorBrandBackgroundInvertedPressed: var(--colorBrandBackgroundInvertedPressed);
$colorBrandBackgroundInvertedSelected: var(--colorBrandBackgroundInvertedSelected);
$colorNeutralStrokeAccessible: var(--colorNeutralStrokeAccessible);
$colorNeutralStrokeAccessibleHover: var(--colorNeutralStrokeAccessibleHover);
$colorNeutralStrokeAccessiblePressed: var(--colorNeutralStrokeAccessiblePressed);
$colorNeutralStrokeAccessibleSelected: var(--colorNeutralStrokeAccessibleSelected);
$colorNeutralStroke1: var(--colorNeutralStroke1);
$colorNeutralStroke1Hover: var(--colorNeutralStroke1Hover);
$colorNeutralStroke1Pressed: var(--colorNeutralStroke1Pressed);
$colorNeutralStroke1Selected: var(--colorNeutralStroke1Selected);
$colorNeutralStroke2: var(--colorNeutralStroke2);
$colorNeutralStroke3: var(--colorNeutralStroke3);
$colorNeutralStrokeSubtle: var(--colorNeutralStrokeSubtle);
$colorNeutralStrokeOnBrand: var(--colorNeutralStrokeOnBrand);
$colorNeutralStrokeOnBrand2: var(--colorNeutralStrokeOnBrand2);
$colorNeutralStrokeOnBrand2Hover: var(--colorNeutralStrokeOnBrand2Hover);
$colorNeutralStrokeOnBrand2Pressed: var(--colorNeutralStrokeOnBrand2Pressed);
$colorNeutralStrokeOnBrand2Selected: var(--colorNeutralStrokeOnBrand2Selected);
$colorBrandStroke1: var(--colorBrandStroke1);
$colorBrandStroke2: var(--colorBrandStroke2);
$colorBrandStroke2Hover: var(--colorBrandStroke2Hover);
$colorBrandStroke2Pressed: var(--colorBrandStroke2Pressed);
$colorBrandStroke2Contrast: var(--colorBrandStroke2Contrast);
$colorCompoundBrandStroke: var(--colorCompoundBrandStroke);
$colorCompoundBrandStrokeHover: var(--colorCompoundBrandStrokeHover);
$colorCompoundBrandStrokePressed: var(--colorCompoundBrandStrokePressed);
$colorNeutralStrokeDisabled: var(--colorNeutralStrokeDisabled);
$colorNeutralStrokeInvertedDisabled: var(--colorNeutralStrokeInvertedDisabled);
$colorTransparentStroke: var(--colorTransparentStroke);
$colorTransparentStrokeInteractive: var(--colorTransparentStrokeInteractive);
$colorTransparentStrokeDisabled: var(--colorTransparentStrokeDisabled);
$colorNeutralStrokeAlpha: var(--colorNeutralStrokeAlpha);
$colorNeutralStrokeAlpha2: var(--colorNeutralStrokeAlpha2);
$colorStrokeFocus1: var(--colorStrokeFocus1);
$colorStrokeFocus2: var(--colorStrokeFocus2);
$colorNeutralShadowAmbient: var(--colorNeutralShadowAmbient);
$colorNeutralShadowKey: var(--colorNeutralShadowKey);
$colorNeutralShadowAmbientLighter: var(--colorNeutralShadowAmbientLighter);
$colorNeutralShadowKeyLighter: var(--colorNeutralShadowKeyLighter);
$colorNeutralShadowAmbientDarker: var(--colorNeutralShadowAmbientDarker);
$colorNeutralShadowKeyDarker: var(--colorNeutralShadowKeyDarker);
$colorBrandShadowAmbient: var(--colorBrandShadowAmbient);
$colorBrandShadowKey: var(--colorBrandShadowKey);
$colorPaletteRedBackground1: var(--colorPaletteRedBackground1);
$colorPaletteRedBackground2: var(--colorPaletteRedBackground2);
$colorPaletteRedBackground3: var(--colorPaletteRedBackground3);
$colorPaletteRedForeground1: var(--colorPaletteRedForeground1);
$colorPaletteRedForeground2: var(--colorPaletteRedForeground2);
$colorPaletteRedForeground3: var(--colorPaletteRedForeground3);
$colorPaletteRedBorderActive: var(--colorPaletteRedBorderActive);
$colorPaletteRedBorder1: var(--colorPaletteRedBorder1);
$colorPaletteRedBorder2: var(--colorPaletteRedBorder2);
$colorPaletteGreenBackground1: var(--colorPaletteGreenBackground1);
$colorPaletteGreenBackground2: var(--colorPaletteGreenBackground2);
$colorPaletteGreenBackground3: var(--colorPaletteGreenBackground3);
$colorPaletteGreenForeground1: var(--colorPaletteGreenForeground1);
$colorPaletteGreenForeground2: var(--colorPaletteGreenForeground2);
$colorPaletteGreenForeground3: var(--colorPaletteGreenForeground3);
$colorPaletteGreenBorderActive: var(--colorPaletteGreenBorderActive);
$colorPaletteGreenBorder1: var(--colorPaletteGreenBorder1);
$colorPaletteGreenBorder2: var(--colorPaletteGreenBorder2);
$colorPaletteDarkOrangeBackground1: var(--colorPaletteDarkOrangeBackground1);
$colorPaletteDarkOrangeBackground2: var(--colorPaletteDarkOrangeBackground2);
$colorPaletteDarkOrangeBackground3: var(--colorPaletteDarkOrangeBackground3);
$colorPaletteDarkOrangeForeground1: var(--colorPaletteDarkOrangeForeground1);
$colorPaletteDarkOrangeForeground2: var(--colorPaletteDarkOrangeForeground2);
$colorPaletteDarkOrangeForeground3: var(--colorPaletteDarkOrangeForeground3);
$colorPaletteDarkOrangeBorderActive: var(--colorPaletteDarkOrangeBorderActive);
$colorPaletteDarkOrangeBorder1: var(--colorPaletteDarkOrangeBorder1);
$colorPaletteDarkOrangeBorder2: var(--colorPaletteDarkOrangeBorder2);
$colorPaletteYellowBackground1: var(--colorPaletteYellowBackground1);
$colorPaletteYellowBackground2: var(--colorPaletteYellowBackground2);
$colorPaletteYellowBackground3: var(--colorPaletteYellowBackground3);
$colorPaletteYellowForeground1: var(--colorPaletteYellowForeground1);
$colorPaletteYellowForeground2: var(--colorPaletteYellowForeground2);
$colorPaletteYellowForeground3: var(--colorPaletteYellowForeground3);
$colorPaletteYellowBorderActive: var(--colorPaletteYellowBorderActive);
$colorPaletteYellowBorder1: var(--colorPaletteYellowBorder1);
$colorPaletteYellowBorder2: var(--colorPaletteYellowBorder2);
$colorPaletteBerryBackground1: var(--colorPaletteBerryBackground1);
$colorPaletteBerryBackground2: var(--colorPaletteBerryBackground2);
$colorPaletteBerryBackground3: var(--colorPaletteBerryBackground3);
$colorPaletteBerryForeground1: var(--colorPaletteBerryForeground1);
$colorPaletteBerryForeground2: var(--colorPaletteBerryForeground2);
$colorPaletteBerryForeground3: var(--colorPaletteBerryForeground3);
$colorPaletteBerryBorderActive: var(--colorPaletteBerryBorderActive);
$colorPaletteBerryBorder1: var(--colorPaletteBerryBorder1);
$colorPaletteBerryBorder2: var(--colorPaletteBerryBorder2);
$colorPaletteLightGreenBackground1: var(--colorPaletteLightGreenBackground1);
$colorPaletteLightGreenBackground2: var(--colorPaletteLightGreenBackground2);
$colorPaletteLightGreenBackground3: var(--colorPaletteLightGreenBackground3);
$colorPaletteLightGreenForeground1: var(--colorPaletteLightGreenForeground1);
$colorPaletteLightGreenForeground2: var(--colorPaletteLightGreenForeground2);
$colorPaletteLightGreenForeground3: var(--colorPaletteLightGreenForeground3);
$colorPaletteLightGreenBorderActive: var(--colorPaletteLightGreenBorderActive);
$colorPaletteLightGreenBorder1: var(--colorPaletteLightGreenBorder1);
$colorPaletteLightGreenBorder2: var(--colorPaletteLightGreenBorder2);
$colorPaletteMarigoldBackground1: var(--colorPaletteMarigoldBackground1);
$colorPaletteMarigoldBackground2: var(--colorPaletteMarigoldBackground2);
$colorPaletteMarigoldBackground3: var(--colorPaletteMarigoldBackground3);
$colorPaletteMarigoldForeground1: var(--colorPaletteMarigoldForeground1);
$colorPaletteMarigoldForeground2: var(--colorPaletteMarigoldForeground2);
$colorPaletteMarigoldForeground3: var(--colorPaletteMarigoldForeground3);
$colorPaletteMarigoldBorderActive: var(--colorPaletteMarigoldBorderActive);
$colorPaletteMarigoldBorder1: var(--colorPaletteMarigoldBorder1);
$colorPaletteMarigoldBorder2: var(--colorPaletteMarigoldBorder2);
$colorPaletteRedForegroundInverted: var(--colorPaletteRedForegroundInverted);
$colorPaletteGreenForegroundInverted: var(--colorPaletteGreenForegroundInverted);
$colorPaletteYellowForegroundInverted: var(--colorPaletteYellowForegroundInverted);
$colorPaletteDarkRedBackground2: var(--colorPaletteDarkRedBackground2);
$colorPaletteDarkRedForeground2: var(--colorPaletteDarkRedForeground2);
$colorPaletteDarkRedBorderActive: var(--colorPaletteDarkRedBorderActive);
$colorPaletteCranberryBackground2: var(--colorPaletteCranberryBackground2);
$colorPaletteCranberryForeground2: var(--colorPaletteCranberryForeground2);
$colorPaletteCranberryBorderActive: var(--colorPaletteCranberryBorderActive);
$colorPalettePumpkinBackground2: var(--colorPalettePumpkinBackground2);
$colorPalettePumpkinForeground2: var(--colorPalettePumpkinForeground2);
$colorPalettePumpkinBorderActive: var(--colorPalettePumpkinBorderActive);
$colorPalettePeachBackground2: var(--colorPalettePeachBackground2);
$colorPalettePeachForeground2: var(--colorPalettePeachForeground2);
$colorPalettePeachBorderActive: var(--colorPalettePeachBorderActive);
$colorPaletteGoldBackground2: var(--colorPaletteGoldBackground2);
$colorPaletteGoldForeground2: var(--colorPaletteGoldForeground2);
$colorPaletteGoldBorderActive: var(--colorPaletteGoldBorderActive);
$colorPaletteBrassBackground2: var(--colorPaletteBrassBackground2);
$colorPaletteBrassForeground2: var(--colorPaletteBrassForeground2);
$colorPaletteBrassBorderActive: var(--colorPaletteBrassBorderActive);
$colorPaletteBrownBackground2: var(--colorPaletteBrownBackground2);
$colorPaletteBrownForeground2: var(--colorPaletteBrownForeground2);
$colorPaletteBrownBorderActive: var(--colorPaletteBrownBorderActive);
$colorPaletteForestBackground2: var(--colorPaletteForestBackground2);
$colorPaletteForestForeground2: var(--colorPaletteForestForeground2);
$colorPaletteForestBorderActive: var(--colorPaletteForestBorderActive);
$colorPaletteSeafoamBackground2: var(--colorPaletteSeafoamBackground2);
$colorPaletteSeafoamForeground2: var(--colorPaletteSeafoamForeground2);
$colorPaletteSeafoamBorderActive: var(--colorPaletteSeafoamBorderActive);
$colorPaletteDarkGreenBackground2: var(--colorPaletteDarkGreenBackground2);
$colorPaletteDarkGreenForeground2: var(--colorPaletteDarkGreenForeground2);
$colorPaletteDarkGreenBorderActive: var(--colorPaletteDarkGreenBorderActive);
$colorPaletteLightTealBackground2: var(--colorPaletteLightTealBackground2);
$colorPaletteLightTealForeground2: var(--colorPaletteLightTealForeground2);
$colorPaletteLightTealBorderActive: var(--colorPaletteLightTealBorderActive);
$colorPaletteTealBackground2: var(--colorPaletteTealBackground2);
$colorPaletteTealForeground2: var(--colorPaletteTealForeground2);
$colorPaletteTealBorderActive: var(--colorPaletteTealBorderActive);
$colorPaletteSteelBackground2: var(--colorPaletteSteelBackground2);
$colorPaletteSteelForeground2: var(--colorPaletteSteelForeground2);
$colorPaletteSteelBorderActive: var(--colorPaletteSteelBorderActive);
$colorPaletteBlueBackground2: var(--colorPaletteBlueBackground2);
$colorPaletteBlueForeground2: var(--colorPaletteBlueForeground2);
$colorPaletteBlueBorderActive: var(--colorPaletteBlueBorderActive);
$colorPaletteRoyalBlueBackground2: var(--colorPaletteRoyalBlueBackground2);
$colorPaletteRoyalBlueForeground2: var(--colorPaletteRoyalBlueForeground2);
$colorPaletteRoyalBlueBorderActive: var(--colorPaletteRoyalBlueBorderActive);
$colorPaletteCornflowerBackground2: var(--colorPaletteCornflowerBackground2);
$colorPaletteCornflowerForeground2: var(--colorPaletteCornflowerForeground2);
$colorPaletteCornflowerBorderActive: var(--colorPaletteCornflowerBorderActive);
$colorPaletteNavyBackground2: var(--colorPaletteNavyBackground2);
$colorPaletteNavyForeground2: var(--colorPaletteNavyForeground2);
$colorPaletteNavyBorderActive: var(--colorPaletteNavyBorderActive);
$colorPaletteLavenderBackground2: var(--colorPaletteLavenderBackground2);
$colorPaletteLavenderForeground2: var(--colorPaletteLavenderForeground2);
$colorPaletteLavenderBorderActive: var(--colorPaletteLavenderBorderActive);
$colorPalettePurpleBackground2: var(--colorPalettePurpleBackground2);
$colorPalettePurpleForeground2: var(--colorPalettePurpleForeground2);
$colorPalettePurpleBorderActive: var(--colorPalettePurpleBorderActive);
$colorPaletteGrapeBackground2: var(--colorPaletteGrapeBackground2);
$colorPaletteGrapeForeground2: var(--colorPaletteGrapeForeground2);
$colorPaletteGrapeBorderActive: var(--colorPaletteGrapeBorderActive);
$colorPaletteLilacBackground2: var(--colorPaletteLilacBackground2);
$colorPaletteLilacForeground2: var(--colorPaletteLilacForeground2);
$colorPaletteLilacBorderActive: var(--colorPaletteLilacBorderActive);
$colorPalettePinkBackground2: var(--colorPalettePinkBackground2);
$colorPalettePinkForeground2: var(--colorPalettePinkForeground2);
$colorPalettePinkBorderActive: var(--colorPalettePinkBorderActive);
$colorPaletteMagentaBackground2: var(--colorPaletteMagentaBackground2);
$colorPaletteMagentaForeground2: var(--colorPaletteMagentaForeground2);
$colorPaletteMagentaBorderActive: var(--colorPaletteMagentaBorderActive);
$colorPalettePlumBackground2: var(--colorPalettePlumBackground2);
$colorPalettePlumForeground2: var(--colorPalettePlumForeground2);
$colorPalettePlumBorderActive: var(--colorPalettePlumBorderActive);
$colorPaletteBeigeBackground2: var(--colorPaletteBeigeBackground2);
$colorPaletteBeigeForeground2: var(--colorPaletteBeigeForeground2);
$colorPaletteBeigeBorderActive: var(--colorPaletteBeigeBorderActive);
$colorPaletteMinkBackground2: var(--colorPaletteMinkBackground2);
$colorPaletteMinkForeground2: var(--colorPaletteMinkForeground2);
$colorPaletteMinkBorderActive: var(--colorPaletteMinkBorderActive);
$colorPalettePlatinumBackground2: var(--colorPalettePlatinumBackground2);
$colorPalettePlatinumForeground2: var(--colorPalettePlatinumForeground2);
$colorPalettePlatinumBorderActive: var(--colorPalettePlatinumBorderActive);
$colorPaletteAnchorBackground2: var(--colorPaletteAnchorBackground2);
$colorPaletteAnchorForeground2: var(--colorPaletteAnchorForeground2);
$colorPaletteAnchorBorderActive: var(--colorPaletteAnchorBorderActive);
$colorStatusSuccessBackground1: var(--colorStatusSuccessBackground1);
$colorStatusSuccessBackground2: var(--colorStatusSuccessBackground2);
$colorStatusSuccessBackground3: var(--colorStatusSuccessBackground3);
$colorStatusSuccessForeground1: var(--colorStatusSuccessForeground1);
$colorStatusSuccessForeground2: var(--colorStatusSuccessForeground2);
$colorStatusSuccessForeground3: var(--colorStatusSuccessForeground3);
$colorStatusSuccessForegroundInverted: var(--colorStatusSuccessForegroundInverted);
$colorStatusSuccessBorderActive: var(--colorStatusSuccessBorderActive);
$colorStatusSuccessBorder1: var(--colorStatusSuccessBorder1);
$colorStatusSuccessBorder2: var(--colorStatusSuccessBorder2);
$colorStatusWarningBackground1: var(--colorStatusWarningBackground1);
$colorStatusWarningBackground2: var(--colorStatusWarningBackground2);
$colorStatusWarningBackground3: var(--colorStatusWarningBackground3);
$colorStatusWarningForeground1: var(--colorStatusWarningForeground1);
$colorStatusWarningForeground2: var(--colorStatusWarningForeground2);
$colorStatusWarningForeground3: var(--colorStatusWarningForeground3);
$colorStatusWarningForegroundInverted: var(--colorStatusWarningForegroundInverted);
$colorStatusWarningBorderActive: var(--colorStatusWarningBorderActive);
$colorStatusWarningBorder1: var(--colorStatusWarningBorder1);
$colorStatusWarningBorder2: var(--colorStatusWarningBorder2);
$colorStatusDangerBackground1: var(--colorStatusDangerBackground1);
$colorStatusDangerBackground2: var(--colorStatusDangerBackground2);
$colorStatusDangerBackground3: var(--colorStatusDangerBackground3);
$colorStatusDangerForeground1: var(--colorStatusDangerForeground1);
$colorStatusDangerForeground2: var(--colorStatusDangerForeground2);
$colorStatusDangerForeground3: var(--colorStatusDangerForeground3);
$colorStatusDangerForegroundInverted: var(--colorStatusDangerForegroundInverted);
$colorStatusDangerBorderActive: var(--colorStatusDangerBorderActive);
$colorStatusDangerBorder1: var(--colorStatusDangerBorder1);
$colorStatusDangerBorder2: var(--colorStatusDangerBorder2);
